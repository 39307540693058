import {defineStore} from "pinia";
import type {ApiResponse} from "~/types/ApiResponse";
import type {FlightItem} from "~/types/flight/list/FlightItem";
import type {RedirectItem} from "~/types/flight/redirect/RedirectItem";
import {getUtmParams} from "~/utils/base/common-utils";


export const useFlightRedirectStore = defineStore('flightRedirect', {
    state: () => ({
        data: <FlightItem>{},
    }),

    actions: {
        async redirect(form: {flight_key: string}) {
            try {
                const response = await useCustomFetch('/api/v1/flight/redirect', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: form,
                }) as ApiResponse<RedirectItem>
                if (!response.success) {
                   return console.log(response.message)
                }
                let url = new URL(response.data.link);

                // ✅ **اضافه کردن UTM با متد `getUtmParams`**
                const utmParams = getUtmParams({
                    source: "parsitrip",
                    medium: "redirect",
                    campaign: "flight",
                });

                url.search += (url.search ? "&" : "?") + utmParams;
                console.log(url.toString());
                window.location.href = url.toString();

            } catch (e: any) {
                console.log(e);
            } finally {
                this.data = {} as FlightItem
            }
        }
    },
    persist: {
        storage: persistedState.localStorage
    }
})
